import { areaList } from '@vant/area-data';
import ToastBox from "../../../components/toast.vue";
import EXIF from 'exif-js';
import {
    shopAuth
} from '@/utils/user.js';
export default {
    name: "StoreAuth",
    setup() {
        return { areaList };
    },
    components: {
        ToastBox,
    },
    data() {
        return {
            showToast: false,
            otherTitle: "",
            columns: ['1周1次', '1月2～3次', '1月1次', '3个月2次'],
            showDate: false,
            showAddr: false,
            formData: {},
            needList: [{
                select: false,
                title: '日常家化'
            }, {
                select: false,
                title: '个护家清'
            }, {
                select: false,
                title: '护肤品'
            }, {
                select: false,
                title: '布艺织品'
            }, {
                select: false,
                title: '小家电'
            }, {
                select: false,
                title: '食品零食'
            }, {
                select: false,
                title: '茶饮酒水'
            }, {
                select: false,
                title: '宠物用品'
            }, {
                select: false,
                title: '酱料调味品'
            }, {
                select: false,
                title: '护理纸制品'
            }, {
                select: false,
                title: '其他品类'
            }],
            goodsList: [{
                select: false,
                title: '线上移动订货',
                desc: '（例如：通过小程序、App订货）'
            }, {
                select: false,
                title: '线下订货',
                desc: '（例如：通过品牌渠道、电话、微信qq订货）'
            }],
            isAuthBtn: false,
        }
    },
    created() {
        // this.init()
    },
    methods: {
        init() {
            const userId = this.$cookies.get('userId')
            if (!userId && userId !== 0) {
                this.showToast = true
            } else {
                this.showToast = false
            }
        },
        handleSure() {
            // this.$router.push({
            //     path: '/login',
            //     query: { redirect: location.hostname }
            // })
        },
        // shopStatus(){
        //     let data = {
        //         userId: this.$cookies.get('userId')
        //     };
        //     getShopAuth(data).then(res => {
        //         if (res) { 
        //             if (res.code == "0") {
        //                 this.status = res.data.status
        //                 // if (this.status == 2) {
        //                 //     const { contacts, cycle, headPic, license, name, phone, inChannel, inDemand, address, city } = res.data || {}
        //                 //     this.formData = { contacts, cycle, headPic, license, name, phone, addrDetail: address, addr: city }
        //                 //     const needArr = inDemand ? inDemand.split(',') : []
        //                 //     const needs = []
        //                 //     this.needList.forEach(item => {
        //                 //         needs.push(item.title)
        //                 //         if (needArr.includes(item.title)) {
        //                 //             item.select = true
        //                 //         }
        //                 //     })
        //                 //     let boolText = ""
        //                 //     needArr.forEach(item => {
        //                 //         if (!needs.includes(item)) {
        //                 //             boolText = item
        //                 //         }
        //                 //     })
        //                 //     if (boolText) {
        //                 //         this.needList[this.needList.length - 1].select = true
        //                 //         this.otherTitle = boolText
        //                 //     }
        //                 //     const goodsArr = inChannel ? inChannel.split(',') : []
        //                 //     this.goodsList.forEach(item => {
        //                 //         if (goodsArr.includes(item.title)) {
        //                 //             item.select = true
        //                 //         }
        //                 //     })
        //                 // }
        //             } else if (res.code == "-1") {
        //                 console.log(res)
        //             } else {
        //                this.$toast.fail(res.message)
        //             } 
        //         }
        //     })
        // },
        // 判断上传的图片是否超出10M
        oversizeFunc(e, name) {
            let aFile = e.target.files[0];
            let _that = this;
            let reader = new FileReader();
            reader.readAsDataURL(aFile);
            reader.onloadstart = function() {
                console.info('开始读取')
            }
            reader.onprogress = function() {
                console.info('正在读取.....')
            }
            reader.onload = function(e) {
                console.log('e', e)
                if (aFile.size > 10 * 1024 * 1024) {
                    _that.$toast('上传的图片大小超过10MB，无法上传');
                    return false;
                }
                _that.uploadImg(this.result, aFile, name);
            }
            reader.onabort = function() {
                console.info('读取中断！！')
            }
            reader.onerror = function() {
                console.info('读取出现错误！！')
            }
            reader.onloadend = function() {
                console.info('FileReader读取步骤执行完毕')
            }
        },
        // 压缩图片
        uploadImg(e, filedata, name) {
            // let aFile = e;
            let _that = this;
            let base64Url = e;
            let maxWidth = 800,
                maxHeight = 800;
            let img = new Image();
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            // base64地址图片加载完毕后
            img.onload = function() {
                // 图片原始尺寸
                let originWidth = this.width;
                let originHeight = this.height;
                console.log('originWidth', originWidth);
                // 目标尺寸
                let targetWidth = originWidth,
                    targetHeight = originHeight;

                if (originWidth > maxWidth || originHeight > maxHeight) {
                    if (originWidth / originHeight > maxWidth / maxHeight) {
                        // 更宽，按照宽度限定尺寸
                        targetWidth = maxWidth;
                        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                    } else {
                        targetHeight = maxHeight;
                        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                    }
                }
                EXIF.getData(filedata, function() {
                    EXIF.getAllTags(this);
                    let orient = EXIF.getTag(this, 'Orientation'); //判断需不需要旋转的值了，有1、3、6、8
                    switch (orient) {
                        case 6: //需要顺时针90度旋转
                            canvas.width = targetHeight;
                            canvas.height = targetWidth;
                            context.rotate(90 * Math.PI / 180);
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, 0, -targetHeight, targetWidth, targetHeight);
                            break;
                        case 8: //需要逆时针90度旋转
                            canvas.width = targetWidth;
                            canvas.height = targetHeight;
                            context.rotate(-90 * Math.PI / 180);
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, -targetHeight, 0, targetHeight, targetWidth);
                            break;
                        case 3: //需要180度旋转
                            context.rotate(180 * Math.PI / 180);
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, -originWidth, -originHeight);
                            break;
                        default:
                            canvas.width = targetWidth;
                            canvas.height = targetHeight;
                            context.fillStyle = "#FFF";
                            context.fillRect(0, 0, targetWidth, targetHeight);
                            context.drawImage(img, 0, 0, targetWidth, targetHeight);
                    }
                    let base = canvas.toDataURL("image/jpeg", 0.7); //canvas转码为base64
                    base64Url = base;
                    _that.formData[name] = base64Url
                });
            };
            img.src = base64Url;
        },
        // 删除图片
        deletePicture(name) {
            this.formData[name] = "";
        },
        activeFun(num, index) {
            if (num == 1) {
                this.needList[index].select = this.needList[index].select ? false : true
            } else {
                this.goodsList[index].select = this.goodsList[index].select ? false : true
            }
        },
        dateConfirm(val) {
            this.showDate = false
            this.formData.cycle = val
        },
        dateCancel() {
            this.showDate = false
        },
        onConfirm(val) {
            this.showAddr = false
            let addr = ''
            const arr = val || []
            arr.forEach(ele => {
                addr += ele.name
            })
            this.formData.addr = addr
        },
        onCancel() {
            this.showAddr = false
            this.formData.cycle = ""
        },
        addrFun() {
            this.showAddr = true
        },
        submit() {
            const formData = JSON.parse(JSON.stringify(this.formData))
            if (!formData.name) {
                return this.$toast('请输入门店名称')
            }
            if (!formData.addr) {
                return this.$toast('请选择门店地址')
            }
            if (!formData.addrDetail) {
                return this.$toast('请输入详细门店地址')
            }
            if (!formData.addrDetail) {
                return this.$toast('请输入详细门店地址')
            }
            if (!formData.license) {
                return this.$toast('请上传门店营业执照')
            }
            if (!formData.headPic) {
                return this.$toast('请上传门店头图')
            }
            if (!this.formData.contacts) {
                return this.$toast('请输入门店联系人姓名')
            }
            if (!formData.phone) {
                return this.$toast('请输入门店联系人联系方式')
            }
            formData.address = formData.addr
            formData.city = formData.addrDetail
            delete formData.addr
            delete formData.addrDetail
            if (!formData.cycle) {
                delete formData.cycle
            }
            const needArr = []
            let other = false
            this.needList.forEach(item => {
                if (item.select) {
                    if (item.title == "其他品类") {
                        if (!this.otherTitle) {
                            other = true
                        } else {
                            needArr.push(this.otherTitle)
                        }
                    } else {
                        needArr.push(item.title)
                    }
                }
            })
            if (other) {
                return this.$toast('门店进货品类需求勾选了其他品类，就要输入它的品类类型')
            }
            if (needArr.length > 0) {
                formData.inDemand = needArr.join(',')
            }
            const goodsArr = []
            this.goodsList.forEach(item => {
                if (item.select) {
                    goodsArr.push(item.title)
                }
            })
            if (goodsArr.length > 0) {
                formData.inChannel = goodsArr.join(',')
            }
            // formData.userId = this.$cookies.get('userId')
            formData.memberId = this.$cookies.get('memberId')
            if (this.isAuthBtn) return false;
            this.isAuthBtn = true;
            shopAuth(formData).then(res => {
                if (res && res.code == "0") {
                    this.$toast.success('提交审核成功！')
                    this.$router.go(-1)
                } else {
                    setTimeout(() => {
                        this.isAuthBtn = false;
                    }, 800)
                }
            }).catch(() => {
                setTimeout(() => {
                    this.isAuthBtn = false;
                }, 800)
            })
        }
    }
}