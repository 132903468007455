<template>
    <div class="store-auth">
        <div class="header">
            <div class="header-top flex align-center">
                <img src="@/assets/img/store/tip.png" alt="">
                <p>认证门店信息后，可以进入门店特卖专场采购更便宜的货!<br>请如实填写您的门店信息</p>
            </div>
        </div>
        <div class="main-top">
          <div class="part-li flex align-center">
            <p class="left-title"><span>*</span> 门店名称</p>
            <input v-model.trim="formData.name" type="text" placeholder="请输入门店名称">
          </div>
          <div class="part-li flex">
            <p class="left-title"><span>*</span> 门店地址</p>
            <div class="addr-box-input">
                <div class="addr-top flex flex-right align-center" @click="addrFun">
                    <input v-model.trim="formData.addr" type="text" placeholder="省/市/区" class="pro-input" readonly>
                    <img src="@/assets/img/store/right.png" class="right-pic">
                </div>
               <input v-model.trim="formData.addrDetail" type="text" placeholder="请输入详细门店地址" class="addr-input">
            </div>
          </div>
          <div class="part-li">
            <p class="left-title"><span>*</span> 门店营业执照</p>
            <div class="picture-box">
              <div v-if="formData.license" class="i-box" :style="{background: 'url('+ formData.license +')no-repeat center center',backgroundSize: 'cover'}">
                <img src="@/assets/img/order/after/close.png" alt="" class="close" @click.stop="deletePicture('license')"/>
              </div>
              <input type="file" accept="image/*" class="upload-input" size="10485760" @change="oversizeFunc($event, 'license')">
              <p class="upload">+</p>
              <p class="upload upload-text">上传图片</p>
            </div>
          </div>
          <div class="part-li">
            <p class="left-title"><span>*</span> 门店头图</p>
            <div class="picture-box">
              <div v-if="formData.headPic" class="i-box" :style="{background: 'url('+ formData.headPic +')no-repeat center center',backgroundSize: 'cover'}">
                <img src="@/assets/img/order/after/close.png" alt="" class="close" @click.stop="deletePicture('headPic')"/>
              </div>
              <input type="file" accept="image/*" class="upload-input" size="10485760" @change="oversizeFunc($event, 'headPic')">
              <p class="upload">+</p>
              <p class="upload upload-text">上传图片</p>
            </div>
          </div>
          <div class="part-li flex align-center">
            <p class="left-title"><span>*</span> 门店联系人姓名</p>
            <input v-model.trim="formData.contacts" type="text" placeholder="请输入门店联系人姓名" maxlength="15">
          </div>
          <div class="part-li flex align-center">
            <p class="left-title"><span>*</span> 门店联系方式</p>
            <input v-model.trim="formData.phone" type="text" maxlength="20" placeholder="请输入门店联系人联系方式" oninput="this.value=this.value.replace(/[^0-9\s-]/g,'')">
          </div>
        </div>
        <p class="center-title">填写以下信息，可获得50龙珠奖励:</p>
        <div class="main-center">
            <div class="part-li flex align-center">
                <p class="left-title">门店进货周期需求</p>
                <div class="right-box-input flex flex-right align-center" @click="showDate = true">
                    <input v-model.trim="formData.cycle" type="text" placeholder="请点击选择" class="pro-input" readonly>
                    <img src="@/assets/img/store/right.png" class="right-pic">
                </div>
            </div>
             <div class="part-li part-sel">
                <p class="left-title">门店进货品类需求(可多选)</p>
               <div class="sel-box flex">
                  <div v-for="(item,index) in needList" :key="index" :class="['sel-part flex align-center', {'sel-three': index % 2 == 0 && item.title.indexOf('其他') == -1 },, {'sel-other': item.title.indexOf('其他') > -1 }]" @click="activeFun(1, index)">
                      <img v-show="!item.select" src="@/assets/img/store/no-select.png" alt="">
                      <img v-show="item.select" src="@/assets/img/store/selected.png" alt="">
                      <p>{{ item.title }}</p>
                      <template v-if="item.title.indexOf('其他') > -1">
                        <input v-model="otherTitle" type="text" placeholder="请输入品类类型" @click.stop>
                      </template>
                  </div>
               </div>
            </div>
            <div class="part-li part-sel last-sel">
                <p class="left-title">进货渠道(可多选)</p>
               <div class="sel-box flex">
                  <div v-for="(item,index) in goodsList" :key="index" :class="['sel-part flex align-center', {'sel-three': index % 2 == 0 && item.title.indexOf('其他') == -1 },, {'sel-other': item.title.indexOf('其他') > -1 }]" @click="activeFun(2, index)">
                      <img v-show="!item.select" src="@/assets/img/store/no-select.png" alt="">
                      <img v-show="item.select" src="@/assets/img/store/selected.png" alt="">
                      <p>{{ item.title }}</p>
                      <i class="desc">{{ item.desc }}</i>
                  </div>
               </div>
            </div>
        </div>
        <div class="submit-box">
           <p class="submit-audit" @click="submit">提交审核</p>
        </div>
      <van-popup
        v-model:show="showAddr"
        position="bottom"
        :style="{ width: '100%' }"
        :close-on-click-overlay="true"
        class="set-addr-popup"
      >
        <van-area title="门店地址" :area-list="areaList" @confirm="onConfirm" @cancel="onCancel"/>
      </van-popup>
      <van-popup
        v-model:show="showDate"
        position="bottom"
        :style="{ width: '100%' }"
        :close-on-click-overlay="true"
        class="set-date-popup"
      >
        <van-picker
          title="门店进货周期需求"
          :columns="columns"
          @confirm="dateConfirm"
          @cancel="dateCancel"
        />
      </van-popup>
      <toast-box
        v-if="showToast"
        :show-toast="showToast"
        msg="您还没有登录，<br>登录之后才能门店认证"
        confirm-text="去登录"
        @sure="handleSure"
      ></toast-box>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>
<style lang="scss">
.store-auth{
  .set-addr-popup,.set-date-popup {
    .van-picker__cancel {
      height: 22px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500 !important;
      color: #e62129 !important;
      line-height: 22px !important;
    }
    .van-picker__title {
      height: 22px !important;
      font-size: 14px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400 !important;
      color: #666666 !important;
      line-height: 22px !important;
    }
    .van-picker__confirm {
      height: 22px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500 !important;
      color: #e62129 !important;
      line-height: 22px !important;
    }
  }
}
</style>